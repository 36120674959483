import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/static/static.module').then(m => m.StaticModule)
  },
  {
    path: 'influencer',
    loadChildren: () => import('./modules/static/static.module').then(m => m.StaticModule)
  },
  {
    path: 'city',
    loadChildren: () => import('./modules/country-launch/country-launch.module').then(m => m.CountryLaunchModule)
  },
  // {
  //   path: ':username',
  //   loadChildren: () => import('./modules/influencer/influencer.module').then(m => m.InfluencerPageModule)
  // },
  {
    path: 'collab/:username',
    loadChildren: () => import('./modules/influencer/influencer.module').then(m => m.InfluencerPageModule)
  },
  {
    path: 'project/:username',
    loadChildren: () => import('./modules/playlist/playlist.module').then(m => m.PlaylistModule)
  },
  {
    path: 'report',
    loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'testimonials',
    loadChildren: () => import('./modules/customers/customers.module').then(m => m.CustomersModule)
  },
  {
    path: 'pingers/:username',
    loadChildren: () => import('./modules/influencer/influencer.module').then(m => m.InfluencerPageModule)
  },
  {
    path: 'blog',
    loadChildren: () => import('./modules/blog/blog.module').then(m => m.BlogModule)
  },
  {
    path: 'pingers',
    loadChildren: () => import('./modules/influencer/influencer.module').then(m => m.InfluencerPageModule)
  },
  {
    path: 'social-connect',
    loadChildren: () => import('./modules/social-connect/social-connect.module').then(m => m.SocialConnectModule)
  },
  {
    path: 'email-preferences',
    loadChildren: () => import('./modules/email-preferences/email-preferences.module').then(m => m.EmailPreferencesModule)
  },
  // {
  //   path: 'presents',
  //   loadChildren: () => import('./modules/podcasts/podcasts.module').then(m => m.PodcastModule)
  // },
  {
    path: 'share',
    loadChildren: () => import('./modules/link-handler/link-handler.module').then(m => m.LinkHandlerModule)
  },
  {
    path: 'qr',
    loadChildren: () => import('./modules/qr/qr.module').then(m => m.QrModule)
  },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'top',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
